import { analytics } from '../libs/Segment';

export const RESULTS_PER_PAGE = 3;

export enum ROLE {
  FREE = 'free',
  STARTER = 'starter',
  VOGUE = 'vogue',
  ADMIN = 'admin',
  CASUAL = 'casual',
  COUTURE = 'couture',
}

export const PAYING_CUSTOMER_PLANS = [ROLE.ADMIN, ROLE.STARTER, ROLE.VOGUE, ROLE.COUTURE, ROLE.CASUAL, ROLE.STARTER];

export enum TYPE_ROUNDED {
  RIGHT = 'RIGHT',
  LEFT = 'LEFT',
  FULL = 'FULL',
}

export enum CARD_TYPE {
  AMOUNT = 'AMOUNT',
  RESOLUTION = 'RESOLUTION',
  SILHOUETTE = 'SILHOUETTE',
}

export enum PRICING_PLAN {
  FREE = 'FREE',
  STARTER = 'STARTER',
  CASUAL = 'CASUAL',
  COUTURE = 'COUTURE',
  VOGUE = 'VOGUE',
  CUSTOM = 'CUSTOM',
}

export enum IMAGE_QUALITY {
  LOW = 0,
  HIGH = 1,
}

export enum ASPECT_RATIO {
  FILM = '2:3',
  ACADEMY = '3:2',
  PORTRAIT = '3:4',
  LANDSCAPE = '4:3',
  SQUARE = '1:1',
}

export const AMOUNT_IMAGES = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
];

export const RESOLUTION_IMAGES = [
  { label: 'Low', value: IMAGE_QUALITY.LOW },
  { label: 'High', value: IMAGE_QUALITY.HIGH },
];

export const RATIO_IMAGES = [
  { name: 'Film (2:3)', value: ASPECT_RATIO.FILM },
  { name: 'Academy (3:2)', value: ASPECT_RATIO.ACADEMY },
  { name: 'Portrait (3:4)', value: ASPECT_RATIO.PORTRAIT },
  { name: 'Landscape (4:3)', value: ASPECT_RATIO.LANDSCAPE },
  { name: 'Square (1:1)', value: ASPECT_RATIO.SQUARE },
];

export const getAuth0Roles = (user): Array<ROLE> => {
  // The role comes as key-value pair, so we need to extract the value
  // The key is the auth0 domain + /roles
  const key = `${import.meta.env.VITE_AUTH0_DOMAIN}/roles`;
  const roles: Array<ROLE> = user ? user[key] : [ROLE.FREE];
  return roles.map((role) => role.toLocaleLowerCase()) as ROLE[];
};

export const getCost = (generationImageAmount: number, generationImageQuality: IMAGE_QUALITY): number => {
  const creditsPerQuality = generationImageQuality === IMAGE_QUALITY.LOW ? 1 : 2;
  const cost = generationImageAmount * creditsPerQuality;
  return cost;
};

export const getFileNameFromUrl = (url: string): string => {
  const urlObject = new URL(url);
  const path = urlObject.pathname;
  const pathSegments = path.split('/');
  const fileName = pathSegments.pop();
  return fileName as string;
};

export const downloadImageByURL = (imgUrl: string) => {
  fetch(imgUrl)
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = getFileNameFromUrl(imgUrl);
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      analytics.track('studio_image_download');
    })
    .catch((error) => {
      console.error('Error downloading image:', error);
    });
};

export const downloadImageByBlobURl = (blobUrl: string) => {
  const a = document.createElement('a');
  a.href = blobUrl;
  a.download = getFileNameFromUrl(blobUrl) + '.jpg';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const downloadImageByBase64 = (base64Data: string, fileName: string = 'image.jpg') => {
  const byteCharacters = atob(base64Data.split(',')[1]!);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'image/jpeg' });

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};
