import { base64ToImageElement } from '../../../libs/imageUtils';

interface ApplyMaskAndGetBase64Schema {
  originalImg: string;
  maskImg: string;
}

// Helper function to load an image from a string (base64 or URL)
function loadImage(src: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = () => reject(new Error('Failed to load image'));
    img.src = src;
  });
}

/**
 * this function applies the mask to the original image and returns the result as a base64 encoded string
 * @returns  base64
 */
export async function applyMaskAndGetMaskBase64({
  maskImg: maskImgSrc,
  originalImg: originalImgSrc,
}: ApplyMaskAndGetBase64Schema): Promise<string> {
  const originalImgElement = await loadImage(originalImgSrc);

  const imageWithoutAlfaBlack = await removeAlfaFromBlack({
    maskImg: maskImgSrc,
    originalImg: originalImgSrc,
  });

  const imageMask = await base64ToImageElement(imageWithoutAlfaBlack);

  return new Promise((resolve, reject) => {
    const img = new Image();

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      reject('Unable to get canvas context');
      return;
    }

    canvas.width = originalImgElement.width;
    canvas.height = originalImgElement.height;

    // First, draw the mask image
    ctx.drawImage(imageMask, 0, 0);

    // Change the global composite operation to 'source-in'
    // This operation will keep only the content that overlaps both the existing canvas content (mask)
    // and the new content being drawn (original image) where the mask is opaque.
    ctx.globalCompositeOperation = 'source-in';

    // Then, draw the original image on top of the mask
    // Due to the 'source-in' composite operation, only the part of the original image
    // that overlaps with the opaque parts of the mask will be shown.
    ctx.drawImage(originalImgElement, 0, 0);

    // Reset the global composite operation to default
    ctx.globalCompositeOperation = 'source-over';

    // Convert the canvas content to a base64 encoded string and resolve the promise with it
    resolve(canvas.toDataURL('image/png'));

    img.onerror = () => {
      reject('Error loading image:');
    };
  });
}

/**
 * this function applies the mask to the original image and returns the result as a base64 encoded string
 * @returns  base64
 */
export async function applyMaskAndGetImageBase64({
  maskImg: maskImgSrc,
  originalImg: originalImgSrc,
}: ApplyMaskAndGetBase64Schema): Promise<string> {
  const originalImgElement = await loadImage(originalImgSrc);

  const imageWithoutAlfaBlack = await removeAlfaFromWhite({
    maskImg: maskImgSrc,
    originalImg: originalImgSrc,
  });

  const imageMask = await base64ToImageElement(imageWithoutAlfaBlack);

  return new Promise((resolve, reject) => {
    const img = new Image();

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      reject('Unable to get canvas context');
      return;
    }

    canvas.width = originalImgElement.width;
    canvas.height = originalImgElement.height;

    // First, draw the mask image
    ctx.drawImage(imageMask, 0, 0);

    // Change the global composite operation to 'source-in'
    // This operation will keep only the content that overlaps both the existing canvas content (mask)
    // and the new content being drawn (original image) where the mask is opaque.
    ctx.globalCompositeOperation = 'source-in';

    // Then, draw the original image on top of the mask
    // Due to the 'source-in' composite operation, only the part of the original image
    // that overlaps with the opaque parts of the mask will be shown.
    ctx.drawImage(originalImgElement, 0, 0);

    // Reset the global composite operation to default
    ctx.globalCompositeOperation = 'source-over';

    // Convert the canvas content to a base64 encoded string and resolve the promise with it
    resolve(canvas.toDataURL('image/png'));

    img.onerror = () => {
      reject('Error loading image:');
    };
  });
}

/**
 * This function removes the alpha from the black color
 * @returns base64
 */
async function removeAlfaFromBlack({ maskImg, originalImg }: ApplyMaskAndGetBase64Schema): Promise<string> {
  const [maskImgElement, originalImgElement] = await Promise.all([
    base64ToImageElement(maskImg),
    base64ToImageElement(originalImg),
  ]);

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('Unable to get canvas context');
  }

  canvas.width = originalImgElement.width;
  canvas.height = originalImgElement.height;

  ctx.drawImage(maskImgElement, 0, 0);
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const data = imageData.data;

  for (let i = 0; i < data.length; i += 4) {
    if (data[i]! < 100 && data[i + 1]! < 100 && data[i + 2]! < 100) {
      data[i + 3] = 0;
    }
  }

  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(originalImgElement, 0, 0);
  ctx.putImageData(imageData, 0, 0);

  return canvas.toDataURL('image/png');
}

/**
 * This function removes the alpha from the white color
 * @returns base64
 */
async function removeAlfaFromWhite({ maskImg, originalImg }: ApplyMaskAndGetBase64Schema): Promise<string> {
  const [maskImgElement, originalImgElement] = await Promise.all([
    base64ToImageElement(maskImg),
    base64ToImageElement(originalImg),
  ]);

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('Unable to get canvas context');
  }

  canvas.width = originalImgElement.width;
  canvas.height = originalImgElement.height;

  ctx.drawImage(maskImgElement, 0, 0);
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const data = imageData.data;

  for (let i = 0; i < data.length; i += 4) {
    if (data[i]! > 200 && data[i + 1]! > 200 && data[i + 2]! > 200) {
      data[i + 3] = 0;
    }
  }

  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(originalImgElement, 0, 0);
  ctx.putImageData(imageData, 0, 0);

  return canvas.toDataURL('image/png');
}

export interface TrimImageResponse {
  image: string;
  x: number;
  y: number;
  width: number;
  height: number;
}

/**
 * @returns base64
 */
export function trimImage(imageSrc: string): Promise<TrimImageResponse> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = imageSrc;

    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        reject('Unable to get canvas context');
        return;
      }

      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

      let top: number | null = null;
      let bottom: number | null = null;
      let left: number | null = null;
      let right: number | null = null;

      // Find the bounds of the non-transparent area
      for (let y = 0; y < canvas.height; y++) {
        for (let x = 0; x < canvas.width; x++) {
          const index = (y * canvas.width + x) * 4;
          if (data[index + 3]! > 0) {
            // Alpha channel check
            if (top === null) top = y;
            if (bottom === null || y > bottom) bottom = y;
            if (left === null) left = x;
            if (right === null || x > right) right = x;

            if (x < left) left = x;
            if (x > right) right = x;
          }
        }
      }

      // Handle case where the image is fully transparent
      if (top === null || bottom === null || left === null || right === null) {
        reject('Image is fully transparent');
        return;
      }

      const width = right - left + 1;
      const height = bottom - top + 1;

      // Create a new canvas to hold the trimmed image
      const trimmedCanvas = document.createElement('canvas');
      const trimmedCtx = trimmedCanvas.getContext('2d');

      if (!trimmedCtx) {
        reject('Unable to get trimmed canvas context');
        return;
      }

      trimmedCanvas.width = width;
      trimmedCanvas.height = height;

      trimmedCtx.drawImage(canvas, left, top, width, height, 0, 0, width, height);

      // Convert the trimmed canvas to a base64 string
      resolve({
        image: trimmedCanvas.toDataURL('image/png'),
        x: left,
        y: top,
        width,
        height,
      });
    };

    image.onerror = () => {
      reject('Error loading image');
    };
  });
}
