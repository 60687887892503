export enum GenerationStatus {
  PROCESSING = 'processing',
  PENDING = 'pending',
  PARTIAL_READY = 'partial_ready',
  PARTIAL_FAIL = 'partial_fail',
  READY = 'ready',
  FAIL = 'fail',
  TIMEOUT = 'timeout',
  ERROR = 'error',
  UPSELL = 'upsell',
  QUOTA = 'quota',
  SUCCESS = 'success',
}
