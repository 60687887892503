import pica from 'pica';
import { toast } from 'react-toastify';

const MAX_WIDTH = 266;
const MAX_HEIGHT = 800;

/**
 * this function converts an image URL to a Blob URL
 * @returns blob url
 */
export async function convertImageUrlToBlobUrl(imageUrl: string) {
  try {
    // Fetch the image
    const response = await fetch(imageUrl);
    // Ensure the request was successful
    if (!response.ok) {
      toast.error('Verify your internet connction and reload the page.');
      return null;
    }
    // Get the Blob from the response
    const imageBlob = await response.blob();
    // Create a URL for the Blob
    const blobUrl = URL.createObjectURL(imageBlob);
    // Return the Blob URL
    return blobUrl;
  } catch (error) {
    console.error('Error converting image URL to Blob URL:', error);
    return null;
  }
}

export async function createEmptyImage(
  width: number,
  height: number,
  color: 'white' | 'black' | 'transparent',
): Promise<string> {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  if (ctx) {
    if (color === 'transparent') {
      ctx.clearRect(0, 0, width, height); // Ensures the canvas is transparent
    } else {
      ctx.fillStyle = color;
      ctx.fillRect(0, 0, width, height);
    }
  }
  return canvas.toDataURL('image/png');
}

/**
 * Converts a Blob to a base64 string.
 * @param blob The blob to convert to base64.
 * @returns A promise that resolves with the base64 string.
 */
export const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

/**
 * Converts a Blob URL to a Base64-encoded string.
 *
 * @param {string} blobUrl - The Blob URL to convert.
 * @returns {Promise<string>} A promise that resolves with the Base64-encoded string.
 */
export function blobUrlToBase64(blobUrl: string): Promise<string> {
  return new Promise((resolve, reject) => {
    // Fetch the Blob from the Blob URL
    fetch(blobUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }
        return response.blob();
      })
      .then((blob) => {
        // Create a FileReader to convert the Blob to Base64
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data as string);
        };
        reader.onerror = (error) => {
          reject(error);
        };
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Converts a Base64-encoded string to a Blob URL.
 *
 * @param {string} base64 - The Base64-encoded string to convert.
 * @returns {string} A Blob URL representing the decoded Base64 data.
 */
export function base64ToBlobUrl(base64: string): string {
  // Split the Base64 string into the data and contentType
  const parts = base64.split(';base64,');
  const contentType = (parts[0] as string).split(':')[1];
  const raw = window.atob(parts[1] as string);
  const rawLength = raw.length;
  const uInt8Array = new Uint8Array(rawLength);

  // Convert the raw data to a Uint8Array
  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  // Create a new Blob from the Uint8Array
  const blob = new Blob([uInt8Array], { type: contentType });

  // Create and return a URL representing the Blob
  return URL.createObjectURL(blob);
}

/**
 * Converts a Base64-encoded string to an HTMLImageElement.
 * @returns {Promise<HTMLImageElement>} HTMLImageElement.
 */
export function base64ToImageElement(base64: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();

    // Set the source of the image to the base64 string
    img.src = base64;

    img.onload = async () => {
      resolve(img);
    };

    img.onerror = () => {
      reject('Error loading image:');
    };

    return img;
  });
}

/**
 * Converts a blob url string to an HTMLImageElement.
 * @returns {Promise<HTMLImageElement>} HTMLImageElement.
 */
export function blobURLToImageElement(blobURL: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();

    // Set the source of the image to the base64 string
    img.src = blobURL;

    img.onload = async () => {
      resolve(img);
    };

    img.onerror = () => {
      reject('Error loading image:');
    };

    return img;
  });
}

/**
 * Processes an image file by resizing it and converting to JPEG format.
 * @param string The image url to process.
 * @returns A promise that resolves with the base64-encoded image.
 */
export const processImageToBase64JPG = (fileURL: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = fileURL;
    img.onload = async () => {
      const canvas = document.createElement('canvas');
      const picaInstance = pica();

      const ratio = Math.min(MAX_WIDTH / img.width, MAX_HEIGHT / img.height, 1);
      canvas.width = img.width * ratio;
      canvas.height = img.height * ratio;

      try {
        await picaInstance.resize(img, canvas);
        const blob = await picaInstance.toBlob(canvas, 'image/jpeg');
        const base64 = await blobToBase64(blob);
        resolve(base64);
      } catch (error) {
        reject(error);
      }
    };
    img.onerror = () => reject(new Error('Failed to load image for processing.'));
  });
};

/**
 * Resizes an image to specified dimensions, converts it to a black and white mask based on alpha transparency,
 * and returns the result as a Base64-encoded data URL.
 *
 * Non-transparent pixels are converted to white, while transparent pixels are converted to black. The resulting
 * image is resized to the target width and height.
 * @returns base64
 */
export const convertToBlackAndWhiteMask = (
  fileURL: string,
  targetWidth?: number,
  targetHeight?: number,
): Promise<string> =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = fileURL;
    img.onload = function () {
      // Create a canvas element
      const canvas = document.createElement('canvas');

      // Set the canvas size to the target dimensions
      canvas.width = targetWidth ?? img.width;
      canvas.height = targetHeight ?? img.height;

      // Get the 2D drawing context
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        reject(new Error('Failed to get canvas context'));
        return;
      }

      // Draw the image onto the canvas scaled to fit the target dimensions
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      // Get the image data from the canvas
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

      // Modify each pixel
      for (let i = 0; i < data.length; i += 4) {
        // Check if the pixel is not fully transparent
        if ((data[i + 3] as number) > 0) {
          // Set the pixel color to white with full opacity
          data[i] = data[i + 1] = data[i + 2] = 255; // R, G, B
          data[i + 3] = 255; // A
        } else {
          // Set the pixel color to black with full opacity
          data[i] = data[i + 1] = data[i + 2] = 0; // R, G, B
          data[i + 3] = 255; // A
        }
      }

      // Put the modified image data back onto the canvas
      ctx.putImageData(imageData, 0, 0);

      // Convert the canvas to a data URL and return it
      resolve(canvas.toDataURL('image/png'));
    };

    img.onerror = function () {
      reject('Failed to load image from URL');
    };
  });

export const convertToWhiteAndBlackMask = (
  fileURL: string,
  targetWidth?: number,
  targetHeight?: number,
): Promise<string> =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = fileURL;
    img.onload = function () {
      // Create a canvas element
      const canvas = document.createElement('canvas');

      // Set the canvas size to the target dimensions
      canvas.width = targetWidth ?? img.width;
      canvas.height = targetHeight ?? img.height;

      // Get the 2D drawing context
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        reject(new Error('Failed to get canvas context'));
        return;
      }

      // Draw the image onto the canvas scaled to fit the target dimensions
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      // Get the image data from the canvas
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

      // Modify each pixel
      for (let i = 0; i < data.length; i += 4) {
        // Check if the pixel is not fully transparent
        if ((data[i + 3] as number) > 0) {
          // Set the pixel color to black with full opacity
          data[i] = data[i + 1] = data[i + 2] = 0; // R, G, B
          data[i + 3] = 255; // A
        } else {
          // Set the pixel color to white with full opacity
          data[i] = data[i + 1] = data[i + 2] = 255; // R, G, B
          data[i + 3] = 255; // A
        }
      }

      // Put the modified image data back onto the canvas
      ctx.putImageData(imageData, 0, 0);

      // Convert the canvas to a data URL and return it
      resolve(canvas.toDataURL('image/png'));
    };

    img.onerror = function () {
      reject('Failed to load image from URL');
    };
  });

export function getImageSize(image: string): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = image;
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = () => {
      reject(new Error('Failed to load image from Blob URL'));
    };
  });
}

export const isRBGAImage = async (file: File): Promise<boolean> => {
  return await new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (!ctx) return;
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0, img.width, img.height);
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      let isRGBA = false;
      for (let i = 0; i < imageData.data.length; i += 4) {
        if ((imageData.data[i + 3] ?? 0) < 255) {
          isRGBA = true;
          break;
        }
      }
      resolve(isRGBA);
    };
    img.src = URL.createObjectURL(file);
  });
};

interface ImageLayerSchema {
  image: string;
  targetWidth?: number;
  targetHeight?: number;
  x?: number;
  y?: number;
}

interface MergeImagesSchema {
  imageBottom: ImageLayerSchema;
  imageTop: ImageLayerSchema;
  canvasSize?: {
    width: number;
    height: number;
  };
}

/**
 * Merges two images into a single image.
 * @returns base64
 */
export const mergeImages = async ({ imageBottom, imageTop, canvasSize }: MergeImagesSchema): Promise<string> =>
  new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      reject(new Error('Failed to get canvas context'));
      return;
    }

    const img1 = new Image();
    img1.crossOrigin = 'anonymous';
    img1.src = imageBottom.image;
    img1.onload = function () {
      canvas.width = canvasSize?.width ?? img1.width;
      canvas.height = canvasSize?.height ?? img1.height;

      ctx.drawImage(
        img1,
        imageBottom.x ?? 0,
        imageBottom.y ?? 0,
        imageBottom.targetWidth ?? img1.width,
        imageBottom.targetHeight ?? img1.height,
      );

      const img2 = new Image();
      img2.crossOrigin = 'anonymous';
      img2.src = imageTop.image;
      img2.onload = function () {
        ctx.drawImage(
          img2,
          imageTop.x ?? 0,
          imageTop.y ?? 0,
          imageTop.targetWidth ?? img2.width,
          imageTop.targetHeight ?? img2.height,
        );
        resolve(canvas.toDataURL('image/png'));
      };
    };
  });

interface MergeMultipleImagesSchema {
  imageLayers: ImageLayerSchema[];
  canvasSize: {
    width: number;
    height: number;
  };
}

/**
 * Merges multiple images into a single image.
 * @returns base64
 */
export const mergeMultipleImages = async ({ imageLayers, canvasSize }: MergeMultipleImagesSchema): Promise<string> =>
  new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = canvasSize.width;
    canvas.height = canvasSize.height;

    if (!ctx) {
      reject(new Error('Failed to get canvas context'));
      return;
    }

    const loadImage = (imageLayer: ImageLayerSchema): Promise<HTMLImageElement> => {
      return new Promise((resolveImage, rejectImage) => {
        const img = new Image();
        img.crossOrigin = 'anonymous';
        img.src = imageLayer.image;
        img.onload = () => resolveImage(img);
        img.onerror = () => rejectImage(new Error(`Failed to load image: ${imageLayer.image}`));
      });
    };

    const drawImages = async () => {
      for (const layer of imageLayers) {
        try {
          const img = await loadImage(layer);

          ctx.drawImage(
            img,
            layer.x ?? 0,
            layer.y ?? 0,
            layer.targetWidth ?? img.width,
            layer.targetHeight ?? img.height,
          );
        } catch (error) {
          console.error(error);
          // Continue with the next image if one fails to load
        }
      }
      resolve(canvas.toDataURL('image/jpeg'));
    };

    drawImages();
  });

// ... rest of the file remains unchanged

export async function isImageOnlyBlack(imageUrl: string): Promise<boolean> {
  return new Promise<boolean>((resolve, reject) => {
    const img = new Image();
    img.src = imageUrl;
    img.crossOrigin = 'Anonymous';

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        reject('Unable to get canvas context');
        return;
      }

      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

      for (let i = 0; i < data.length; i += 4) {
        const red = data[i];
        const green = data[i + 1];
        const blue = data[i + 2];
        const alpha = data[i + 3];

        // Check if the pixel is not black
        if (red !== 0 || green !== 0 || blue !== 0 || alpha !== 255) {
          resolve(false);
          return;
        }
      }

      resolve(true);
    };

    img.onerror = (error) => {
      reject('Error loading image: ' + error);
    };
  });
}

export async function isImageOnlyWhite(imageUrl: string): Promise<boolean> {
  return new Promise<boolean>((resolve, reject) => {
    const img = new Image();
    img.src = imageUrl;
    img.crossOrigin = 'Anonymous';

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        reject('Unable to get canvas context');
        return;
      }

      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

      for (let i = 0; i < data.length; i += 4) {
        const red = data[i];
        const green = data[i + 1];
        const blue = data[i + 2];
        const alpha = data[i + 3];

        // Check if the pixel is not black
        if (red !== 255 || green !== 255 || blue !== 255 || alpha !== 255) {
          resolve(false);
          return;
        }
      }

      resolve(true);
    };

    img.onerror = (error) => {
      reject('Error loading image: ' + error);
    };
  });
}

/**
 * return base64
 */
export async function resizeImage(imageUrl: string, width: number, height: number): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const img = new Image();
    img.src = imageUrl;
    img.crossOrigin = 'Anonymous'; // Handle cross-origin issues if necessary

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        reject('Unable to get canvas context');
        return;
      }

      // Set canvas size to the desired width and height
      canvas.width = width;
      canvas.height = height;

      // Draw the image on the canvas with the new size
      ctx.drawImage(img, 0, 0, width, height);

      // Convert the canvas content to a base64 encoded string and resolve the promise with it
      resolve(canvas.toDataURL('image/png'));
    };

    img.onerror = (error) => {
      reject('Error loading image: ' + error);
    };
  });
}

interface CutImageInSpecificSizeProps {
  imageSrc: string;
  image: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
  size: {
    width: number;
    height: number;
  };
}

export const cutImageInSpecificSize = async ({ imageSrc, image, size }: CutImageInSpecificSizeProps): Promise<string> =>
  new Promise((resolve, reject) => {
    const newImage = new Image();
    newImage.src = imageSrc;

    newImage.onload = async () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        reject('Unable to get canvas context');
        return null;
      }

      canvas.width = size.width;
      canvas.height = size.height;

      ctx.drawImage(newImage, 0, 0);
      // Create another canvas for cropping
      const croppedCanvas = document.createElement('canvas');
      const croppedCtx = croppedCanvas.getContext('2d');

      if (!croppedCtx) {
        reject('Unable to get cropped canvas context');
        return null;
      }

      // Set the cropped canvas size to the image size
      croppedCanvas.width = image.width;
      croppedCanvas.height = image.height;

      // Draw the cropped area from the main canvas
      croppedCtx.drawImage(
        canvas,
        image.x,
        image.y,
        image.width,
        image.height, // Source coordinates and size
        0,
        0,
        image.width,
        image.height, // Destination coordinates and size
      );

      // Convert the cropped canvas content to a base64 encoded string and resolve the promise with it
      resolve(croppedCanvas.toDataURL('image/png'));
    };

    newImage.onerror = () => {
      reject('Error loading image:');
    };
  });

interface EraseImageParams {
  image1Src: string;
  image2: {
    src: string;
    x: number;
    y: number;
    width: number;
    height: number;
  };
}

export function eraseImage({ image2, image1Src }: EraseImageParams): Promise<string> {
  return new Promise((resolve) => {
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');

    if (!ctx) {
      console.error('Canvas context is not supported');
      return;
    }

    const Image1 = new Image();
    const Image2 = new Image();

    Image1.onload = () => {
      canvas.width = Image1.width;
      canvas.height = Image1.height;

      ctx.drawImage(Image1, 0, 0);

      Image2.onload = () => {
        ctx.globalCompositeOperation = 'destination-out';
        ctx.drawImage(Image2, image2.x, image2.y, image2.width, image2.height);

        resolve(canvas.toDataURL('image/png'));
      };

      Image2.src = image2.src;
    };

    Image1.src = image1Src;
  });
}

export const convertBlackWhiteMaskToMask = (image: string): Promise<string> =>
  new Promise((resolve, reject) => {
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');

    if (!ctx) {
      console.error('Canvas context is not supported');
      return reject('Canvas context is not supported');
    }

    const img = new Image();
    img.src = image;
    img.crossOrigin = 'Anonymous';

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;

      ctx.drawImage(img, 0, 0);

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

      for (let i = 0; i < data.length; i += 4) {
        const red = data[i];
        const green = data[i + 1];
        const blue = data[i + 2];

        // Check if the pixel is white
        if (red === 255 && green === 255 && blue === 255) {
          data[i + 3] = 0; // Set alpha to 0 (transparent)
        } else {
          data[i] = 134; // Set red to 134
          data[i + 1] = 239; // Set green to 239
          data[i + 2] = 172; // Set blue to 172
          data[i + 3] = 255;
        }
      }

      // Put the modified image data back onto the canvas
      ctx.putImageData(imageData, 0, 0);

      // Convert the canvas to a data URL and return it
      resolve(canvas.toDataURL('image/png'));
    };

    img.onerror = () => {
      reject('Error loading image');
    };
  });

export const convertWhiteBlackMaskToMask = (image: string): Promise<string> =>
  new Promise((resolve, reject) => {
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');

    if (!ctx) {
      console.error('Canvas context is not supported');
      return reject('Canvas context is not supported');
    }

    const img = new Image();
    img.src = image;
    img.crossOrigin = 'Anonymous';

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;

      ctx.drawImage(img, 0, 0);

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

      for (let i = 0; i < data.length; i += 4) {
        const red = data[i];
        const green = data[i + 1];
        const blue = data[i + 2];

        // Check if the pixel is black
        if (red === 0 && green === 0 && blue === 0) {
          data[i + 3] = 0; // Set alpha to 0 (transparent)
        } else {
          data[i] = 134; // Set red to 134
          data[i + 1] = 239; // Set green to 239
          data[i + 2] = 172; // Set blue to 172
          data[i + 3] = 255;
        }
      }

      // Put the modified image data back onto the canvas
      ctx.putImageData(imageData, 0, 0);

      // Convert the canvas to a data URL and return it
      resolve(canvas.toDataURL('image/png'));
    };

    img.onerror = () => {
      reject('Error loading image');
    };
  });

/**
 * Converts an image URL to a base64-encoded string.
 * @param imageUrl The URL of the image to convert.
 * @returns A promise that resolves with the base64-encoded string of the image.
 */
export function imageUrlToBase64(imageUrl: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        reject(new Error('Failed to get canvas context'));
        return;
      }
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL('image/png');
      resolve(dataURL);
    };
    img.onerror = () => {
      reject(new Error('Failed to load image'));
    };
    img.src = imageUrl;
  });
}

export const isTransparentPixel = (
  image: string,
  relativePos: {
    x: number;
    y: number;
  },
): Promise<boolean> =>
  new Promise((resolve) => {
    const img = new Image();
    img.src = image;

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      if (!context) {
        resolve(false);
        return;
      }

      canvas.width = img.width;
      canvas.height = img.height;
      context.drawImage(img, 0, 0, img.width, img.height);

      const pixelData = context.getImageData(Math.round(relativePos.x), Math.round(relativePos.y), 1, 1).data;

      resolve(pixelData[3] === 0); // Check alpha channel
    };
    img.onerror = () => resolve(false);
  });
