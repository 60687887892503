import { useAuth0 } from '@auth0/auth0-react';
import { ROLE, getAuth0Roles } from '../utils';

export enum RoleMode {
  /**
   * Returns true if the user has all of the specified roles.
   */
  All = 'all',

  /**
   * Returns true if the user has at least one of the specified roles.
   */
  Some = 'some',
}

interface useUserHasRoleProps {
  roles: ROLE[];
  mode: RoleMode;
}

export const useUserHasRole = ({ mode, roles }: useUserHasRoleProps) => {
  const { user } = useAuth0();
  const userRoles = getAuth0Roles(user);

  if (roles.length === 0) return true;

  if (mode === 'all') return roles.every((role) => userRoles.includes(role));
  return roles.some((role) => userRoles.includes(role));
};
