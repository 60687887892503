import { Suspense, lazy } from 'react';
import {
  Route,
  RouterProvider,
  createRoutesFromElements,
} from 'react-router-dom';
import sentryCreateBrowserRouter from './libs/Sentry';
import './utils/intercom-changes.css';
import { Loading } from './components/ui/Loading';
import { Page, RoutePrivacy } from './routes/data/route';
import { PrivateRoute, PublicRoute } from './routes/ValidateRoute';
import ErrorPage from './pages/Errors';
import { Outlet } from 'react-router-dom';
import { useRoutes } from './routes/routes';
const RootLayout = lazy(() => import('./pages/Root'));

function App() {
  const routes = useRoutes();
  const privateRoutes = routes.filter(
    (route) => route.privacy === RoutePrivacy.Private,
  );

  const publicRoutes = routes.filter(
    (route) => route.privacy === RoutePrivacy.Public,
  );
  const Auth0ProviderLayout = () => <Outlet />;

  const router = sentryCreateBrowserRouter(
    createRoutesFromElements(
      <Route element={<Auth0ProviderLayout />}>
        <Route
          element={<RootLayout />}
          errorElement={<ErrorPage pageType={Page.Wrong} />}
        >
          {privateRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <PrivateRoute
                  key={route.path}
                  permissions={route.permissions}
                  featureFlag={route.featureFlag}
                >
                  {route.element}
                </PrivateRoute>
              }
            />
          ))}
        </Route>

        {publicRoutes.map((route) => (
          <Route
            path={route.path}
            key={route.path}
            element={
              <PublicRoute key={route.path} featureFlag={route.featureFlag}>
                {route.element}
              </PublicRoute>
            }
          />
        ))}
      </Route>,
    ),
  );

  return (
    <Suspense fallback={<Loading />}>
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;
