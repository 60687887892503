import { Navigate, useLocation } from 'react-router-dom';
import {
  PrivateRoutePropsSchema,
  PublicRoutePropsSchema,
} from './ValidateRoute.schema';
import { useAuth0 } from '@auth0/auth0-react';
import { RoleMode, useUserHasRole } from '../hooks/userHasRole';
import {
  checkFeatureFlag,
  useUserHasStarterOrHigherRole,
} from '../hooks/permissions/usePermissionChecks';

export const PrivateRoute = ({
  children,
  permissions,
  featureFlag,
}: PrivateRoutePropsSchema) => {
  const location = useLocation();

  const { user, isAuthenticated } = useAuth0();

  const userHasStarterOrHigherRole = useUserHasStarterOrHigherRole();
  const userHasFeatureFlag = featureFlag ? checkFeatureFlag(featureFlag) : true;

  const userHasRoutePermissions = useUserHasRole({
    mode: RoleMode.Some,
    roles: permissions,
  });

  const userHasPermissionToAccessCurrentPage =
    userHasStarterOrHigherRole && userHasRoutePermissions;

  if (!isAuthenticated) {
    return (
      <Navigate
        state={{
          from: location.pathname,
        }}
        to={{
          pathname: '/login',
        }}
      />
    );
  }

  if (
    !userHasPermissionToAccessCurrentPage &&
    !location.pathname.includes('subscriptions')
  ) {
    if (!user?.email_verified) {
      window.location.replace(
        import.meta.env.VITE_SELF_SERVICE_BASE_URL + '/api/auth/logout',
      );
      return <></>;
    }

    window.location.replace(
      import.meta.env.VITE_SELF_SERVICE_BASE_URL + '/pricing',
    );
  }

  if (!userHasFeatureFlag)
    return (
      <Navigate
        state={{
          from: location.pathname,
        }}
        to={{
          pathname: '/retouch',
        }}
      />
    );

  return children;
};

export const PublicRoute = ({
  children,
  featureFlag,
}: PublicRoutePropsSchema) => {
  const location = useLocation();
  const hasFeatureFlag = featureFlag ? checkFeatureFlag(featureFlag) : true;

  const { user } = useAuth0();

  if (!hasFeatureFlag) {
    return (
      <Navigate
        state={{
          from: location.pathname,
        }}
        to={{
          pathname: '/login',
        }}
      />
    );
  }

  return !user ? (
    children
  ) : (
    <Navigate
      to={{
        pathname: location.state?.from || '/',
      }}
    />
  );
};
