import { useAuth0 } from '@auth0/auth0-react';
import { ROLE, getAuth0Roles } from '../../utils';
import { RoleMode, useUserHasRole } from '../userHasRole';

export const useUserHasFreeRole = () => {
  const { user } = useAuth0();
  const userRoles = getAuth0Roles(user);

  return (
    useUserHasRole({
      roles: [ROLE.FREE],
      mode: RoleMode.All,
    }) || userRoles.length === 0
  );
};

export const useUserHasStarterOrHigherRole = () => {
  return useUserHasRole({
    roles: [ROLE.STARTER, ROLE.CASUAL, ROLE.COUTURE, ROLE.VOGUE, ROLE.ADMIN],
    mode: RoleMode.Some,
  });
};

export const useUserHasCasualOrHigherRole = () => {
  return useUserHasRole({
    roles: [ROLE.CASUAL, ROLE.COUTURE, ROLE.VOGUE, ROLE.ADMIN],
    mode: RoleMode.Some,
  });
};

export const useUserHasCoutureOrHigherRole = () => {
  return useUserHasRole({
    roles: [ROLE.COUTURE, ROLE.VOGUE, ROLE.ADMIN],
    mode: RoleMode.Some,
  });
};

export const useUserHasVogueOrHigherRole = () => {
  return useUserHasRole({
    roles: [ROLE.VOGUE, ROLE.ADMIN],
    mode: RoleMode.Some,
  });
};

export const useUserHasVoguePermission = () => {
  return useUserHasRole({
    roles: [ROLE.VOGUE],
    mode: RoleMode.All,
  });
};

export const useUserHasAdminPermission = () => {
  return useUserHasRole({
    roles: [ROLE.ADMIN],
    mode: RoleMode.All,
  });
};

export type FeatureFlag = 'styles' | 'multiple-styles' | 'area-transfer';

export const checkFeatureFlag = (flag: FeatureFlag): boolean => {
  switch (flag) {
    case 'styles':
      return import.meta.env.VITE_FEATURE_STYLE_ENABLED === 'true';
    case 'multiple-styles':
      return import.meta.env.VITE_FEATURE_MULTIPLE_STYLES_SELECT === 'true';
    case 'area-transfer':
      return import.meta.env.VITE_FEATURE_AREA_TRANSFER === 'true';
  }
};
