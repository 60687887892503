import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import * as Sentry from '@sentry/react';

export const useAuth0AccessToken = () => {
  const { getAccessTokenSilently } = useAuth0();

  const getCurrentUserAcessToken = useCallback(async () => {
    try {
      return await getAccessTokenSilently();
    } catch (error) {
      const expectedsErrors = ['Login required'];
      if (expectedsErrors.includes((error as Error).message)) return;

      Sentry.captureException(error, {
        extra: {
          error: 'Error during getAccessTokenSilently to connect with socket.io',
        },
      });
    }
  }, [getAccessTokenSilently]);

  return { getCurrentUserAcessToken };
};
