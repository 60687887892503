import Wrong from '../assets/500.svg';

import PageNotFound from '../assets/404.svg';
import { Page } from '../routes/data/route';
interface IProps {
  pageType: Page;
}

export default function ErrorPage({ pageType }: IProps) {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      {pageType === Page.PageNotFound ? notFoundMessage : wrongMessage}
    </div>
  );
}

const notFoundMessage: JSX.Element = (
  <div className="flex w-full flex-col items-center justify-center md:w-full lg:w-1/2 xl:w-1/2">
    <img src={PageNotFound} className="w-1/2" />
    <div className="w-full">
      <p className="mb-2 text-center text-3xl text-white">Page Not Found</p>
      <p className="text-center text-base text-gray-300">
        Oops! Looks like you followed a bad link. If you think this is a problem
        with us, please tell us.
      </p>
    </div>
  </div>
);
const wrongMessage: JSX.Element = (
  <div className="flex w-full flex-col items-center justify-center px-10 md:w-full lg:w-1/2 xl:w-1/2">
    <img src={Wrong} className="w-1/2" />
    <div className="w-full">
      <p className="mb-2 text-center text-3xl text-white">
        Something has gone seriously wrong
      </p>
      <p className="text-center text-base text-gray-300">
        It's always time for a coffee break. We should be back by the time you
        finish your coffee
      </p>
    </div>
  </div>
);
