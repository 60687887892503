/* eslint-disable react-refresh/only-export-components */
import { Page, RoutePrivacy } from './data/route';
import { ROLE } from '../utils';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { FeatureFlag } from '../hooks/permissions/usePermissionChecks';
import { RetouchProvider } from '../components/retouch/context/RetouchContext';
const Login = lazy(() => import('../pages/Login'));
const StripePricingTable = lazy(() => import('../pages/StripePricingTable'));
const RetouchSessionPage = lazy(() => import('../pages/RetouchSessions'));
const RetouchPage = lazy(() => import('../pages/Retouch'));
const ErrorPage = lazy(() => import('../pages/Errors'));

type IRoute = {
  path: string;
  privacy: RoutePrivacy;
  permissions: ROLE[];
  element: JSX.Element;
  featureFlag?: FeatureFlag;
};

export const useRoutes = () => {
  const routes: IRoute[] = [
    {
      element: (
        <RetouchProvider>
          <RetouchSessionPage />
        </RetouchProvider>
      ),
      path: '/retouch',
      privacy: RoutePrivacy.Private,
      permissions: [ROLE.CASUAL, ROLE.COUTURE, ROLE.VOGUE, ROLE.ADMIN],
    },
    {
      element: (
        <RetouchProvider>
          <RetouchPage />
        </RetouchProvider>
      ),
      path: '/retouch/:sessionId',
      privacy: RoutePrivacy.Private,
      permissions: [ROLE.CASUAL, ROLE.COUTURE, ROLE.VOGUE, ROLE.ADMIN],
    },
    // {
    //   element: <AreaTransfer />,
    //   path: '/transfer',
    //   privacy: RoutePrivacy.Private,
    //   permissions: [ROLE.CASUAL, ROLE.COUTURE, ROLE.VOGUE, ROLE.ADMIN],
    //   featureFlag: 'area-transfer',
    // },
    {
      element: <StripePricingTable />,
      path: '/subscriptions',
      privacy: RoutePrivacy.Private,
      permissions: [],
    },
    {
      element: <Login />,
      path: '/login',
      privacy: RoutePrivacy.Public,
      permissions: [],
    },
    {
      element: <Navigate to={`/login${location.search}`} />,
      path: '/register',
      privacy: RoutePrivacy.Public,
      permissions: [],
    },

    {
      element: <ErrorPage pageType={Page.Wrong} />,
      path: '/error',
      privacy: RoutePrivacy.Public,
      permissions: [],
    },
    {
      element: <ErrorPage pageType={Page.PageNotFound} />,
      path: '*',
      privacy: RoutePrivacy.Public,
      permissions: [],
    },
  ];
  return routes;
};
