import { Spinner } from 'flowbite-react';
import { cn } from '../../lib/ui';
interface LoadingProps {
  absolute?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
}

export const Loading = ({ absolute, size = 'xl', className }: LoadingProps) => {
  return (
    <div
      className={cn(
        'fixed inset-0 z-50 flex items-center justify-center bg-black/40',
        absolute && 'absolute',
        className,
      )}
    >
      <Spinner
        theme={{
          color: { primary: 'fill-sliderGreen' },
          base: 'inline animate-spin text-gray-700',
        }}
        color="primary"
        size={size}
      />
    </div>
  );
};
