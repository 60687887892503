import Analytics from 'analytics';
import segmentPlugin from '@analytics/segment';

export const analytics = Analytics({
  plugins: [
    segmentPlugin({
      writeKey: import.meta.env.VITE_SEGMENT_WRITE_KEY,
    }),
  ],
});
