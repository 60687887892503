import React from 'react';
import * as Sentry from '@sentry/react';

import {
  createBrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';

export const feedback = Sentry.feedbackIntegration({
  // Additional SDK configuration goes in here, for example:
  colorScheme: 'light',
  autoInject: false,
});

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  ignoreErrors: ['TypeError: Failed to fetch', 'TypeError: NetworkError when attempting to fetch resource.'],
  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),

    Sentry.replayIntegration({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
      networkDetailAllowUrls: [
        `${import.meta.env.VITE_API_BASE_URL}${import.meta.env.VITE_RETOUCH_BASE_PATH}/ai/tools`,
      ],
      networkRequestHeaders: ['X-Custom-Header'],
      networkResponseHeaders: ['X-Custom-Header'],
    }),
    feedback,
    Sentry.replayCanvasIntegration(),

    //new Sentry.BrowserProfilingIntegration(), //Uncomment to enable Sentry Profiling
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  //Enable Sentry in Prod
  enabled: import.meta.env.VITE_ENVIRONMENT !== 'local',
  enableTracing: import.meta.env.VITE_ENVIRONMENT !== 'local',
  environment: import.meta.env.VITE_ENVIRONMENT,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', import.meta.env.VITE_API_GEE_ENDPOINT],
  // profilesSampleRate: 1.0, //UnComment for Sentry Profiling Parameters
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});

if (import.meta.env.VITE_ENVIRONMENT == 'local') {
  Sentry.init({});
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export default sentryCreateBrowserRouter;
