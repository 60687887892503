import { v4 } from 'uuid';
import { ILayer, IShapesSchema } from '../../../schemas/retouch.schema';

export enum CanvasTool {
  Square = 'square',
  Brush = 'brush',
  Eraser = 'eraser',
  MagicWand = 'magic-wand',
  Select = 'select',
  Lasso = 'lasso',
  Circle = 'circle',
}

export const enum CanvasSize {
  Width = 1024,
  Height = 1536,
}

export const emptyShapes: IShapesSchema = {
  brushes: [],
  squares: [],
  magicWandPoints: [],
  lassos: [],
};

export const emptyLayer = (layers: ILayer[], { ...override }: Partial<ILayer> = {}): ILayer => {
  return {
    id: v4(),
    order: layers.length,
    image: null,
    name: `Layer ${layers.length + 1}`,
    opacity: 1,
    visible: true,
    locked: false,
    generations: [],
    ...override,
  };
};
